var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "기본정보" } },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: {
                          url: _vm.saveUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.dangerStore,
                          mappingType: _vm.saveType,
                          label: "저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveMst,
                          btnCallback: _vm.saveCallback,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-text", {
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    label: "품명 표기",
                    name: "chmDangerMstName",
                  },
                  model: {
                    value: _vm.dangerStore.chmDangerMstName,
                    callback: function ($$v) {
                      _vm.$set(_vm.dangerStore, "chmDangerMstName", $$v)
                    },
                    expression: "dangerStore.chmDangerMstName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-select", {
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    type: "edit",
                    codeGroupCd: "CHM_DANGER_TYPE_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "chmDangerTypeCd",
                    label: "구분",
                  },
                  model: {
                    value: _vm.dangerStore.chmDangerTypeCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.dangerStore, "chmDangerTypeCd", $$v)
                    },
                    expression: "dangerStore.chmDangerTypeCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-text", {
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    label: "설치장소",
                    name: "chmDangerArea",
                  },
                  model: {
                    value: _vm.dangerStore.chmDangerArea,
                    callback: function ($$v) {
                      _vm.$set(_vm.dangerStore, "chmDangerArea", $$v)
                    },
                    expression: "dangerStore.chmDangerArea",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-datepicker", {
                  attrs: {
                    required: true,
                    editable: _vm.editable,
                    label: "설치허가일",
                    name: "installPermitDate",
                  },
                  model: {
                    value: _vm.dangerStore.installPermitDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.dangerStore, "installPermitDate", $$v)
                    },
                    expression: "dangerStore.installPermitDate",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    label: "허가번호",
                    name: "permitNo",
                  },
                  model: {
                    value: _vm.dangerStore.permitNo,
                    callback: function ($$v) {
                      _vm.$set(_vm.dangerStore, "permitNo", $$v)
                    },
                    expression: "dangerStore.permitNo",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-multi-field", {
                  attrs: {
                    editable: _vm.editable,
                    data: _vm.dangerStore,
                    label: "안전관리자",
                    type: "user",
                    name: "chmDangerManageUserId",
                  },
                  model: {
                    value: _vm.dangerStore.chmDangerManageUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.dangerStore, "chmDangerManageUserId", $$v)
                    },
                    expression: "dangerStore.chmDangerManageUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-multi-field", {
                  attrs: {
                    editable: _vm.editable,
                    data: _vm.dangerStore,
                    label: "담당자",
                    type: "user",
                    name: "chmDangerUserId",
                  },
                  model: {
                    value: _vm.dangerStore.chmDangerUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.dangerStore, "chmDangerUserId", $$v)
                    },
                    expression: "dangerStore.chmDangerUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-multi-field", {
                  attrs: {
                    editable: _vm.editable,
                    data: _vm.dangerStore,
                    label: "안전대리자",
                    type: "user",
                    name: "chmDangerSafetyUserId",
                  },
                  model: {
                    value: _vm.dangerStore.chmDangerSafetyUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.dangerStore, "chmDangerSafetyUserId", $$v)
                    },
                    expression: "dangerStore.chmDangerSafetyUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    label: "지정수량 표기",
                    name: "chmDangerCount",
                  },
                  model: {
                    value: _vm.dangerStore.chmDangerCount,
                    callback: function ($$v) {
                      _vm.$set(_vm.dangerStore, "chmDangerCount", $$v)
                    },
                    expression: "dangerStore.chmDangerCount",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    label: "주용도",
                    name: "chmDangerMainUse",
                  },
                  model: {
                    value: _vm.dangerStore.chmDangerMainUse,
                    callback: function ($$v) {
                      _vm.$set(_vm.dangerStore, "chmDangerMainUse", $$v)
                    },
                    expression: "dangerStore.chmDangerMainUse",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-plant", {
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    type: "edit",
                    name: "plantCd",
                  },
                  model: {
                    value: _vm.dangerStore.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.dangerStore, "plantCd", $$v)
                    },
                    expression: "dangerStore.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-checkbox", {
                  attrs: {
                    editable: _vm.editable,
                    isFlag: true,
                    label: "사용여부",
                    name: "useFlag",
                  },
                  model: {
                    value: _vm.dangerStore.useFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.dangerStore, "useFlag", $$v)
                    },
                    expression: "dangerStore.useFlag",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "tableType",
          attrs: {
            title: "저장품목",
            columns: _vm.gridType.columns,
            gridHeight: _vm.gridHeight,
            data: _vm.dangerStore.typeList,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            noDataLabel: "저장품목을 추가하세요.",
            hideBottom: true,
            editable: _vm.editable,
            rowKey: "chmDangerMstTypeId",
            selection: "multiple",
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "calcuMethod"
                    ? [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("toThousandFilter")(
                                props.row["calcuOriginal"] *
                                  props.row["calcuMultiple"]
                              )
                            ) +
                            " "
                        ),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: {
                          showLoading: false,
                          label: "추가",
                          icon: "add",
                        },
                        on: { btnClicked: _vm.addType },
                      })
                    : _vm._e(),
                  _vm.editable &&
                  _vm.dangerStore.typeList &&
                  _vm.dangerStore.typeList.length > 0
                    ? _c("c-btn", {
                        attrs: {
                          showLoading: false,
                          label: "제외",
                          icon: "remove",
                        },
                        on: { btnClicked: _vm.removeType },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }